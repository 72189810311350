<template>
    <div class="pad-tb-1 workplace-my-appeal">
        <el-tabs v-model="tabInfo.active"  @tab-click="handleTabClick">
            <el-tab-pane
                v-for="(item,index) in tabInfo.list"
                :key="index"
                :name="item.value"
            >
                <!-- :label="item.label" -->
             <template v-slot:label>
                        <p
                            class="tabItem-label"
                            :class="'tabItem-label-'+index"
                        >
                            <span>{{item.label}}</span>
                            <span
                                v-if="item.count"
                                class="tabItem-count"
                            >{{item.count}}</span>
                        </p>
                    </template>
            </el-tab-pane>
        </el-tabs>
        <el-form
            class="pad-rl-2 appeal-search-form"
            :model="searchInfo.data"
            inline
        >
            <el-form-item label="事项名称">
                <FormInput v-model="searchInfo.data.sxname"></FormInput>
            </el-form-item>
            <el-form-item label="开始日期">
                <FormDatePicker
                    v-model="searchInfo.data.starttime"
                    :endDate.sync="searchInfo.data.endtime"
                ></FormDatePicker>
            </el-form-item>
            <el-form-item label="结束日期">
                <FormDatePicker
                    v-model="searchInfo.data.endtime"
                    :startDate.sync="searchInfo.data.starttime"
                ></FormDatePicker>
            </el-form-item>
            <el-form-item label="诉求类别">
                <FormSelect
                    v-model="searchInfo.data.sqtypeone"
                    :options="searchInfo.themeList"
                    valueKey="label"
                ></FormSelect>
            </el-form-item>
            <el-form-item label="是否加急">
                <FormSelect
                    v-model="searchInfo.data.sfjj"
                    :options="getDictList('yes_or_no')"
                    valueKey="key"
                ></FormSelect>
            </el-form-item>
            <div>
                <el-button
                    size="small"
                    type="primary"
                    @click="search"
                >搜索</el-button>
                <el-button
                    size="small"
                    @click="reset"
                >重置</el-button>
            </div>
        </el-form>
        <div class="pad-rl-2">
            <div
                v-for="(item, index) in appealInfo.data"
                :key="index"
                class="marg-tb-2 pointer appeal-item"
                @click="clickItem(item)"
            >
                <h3>
                    <span>{{ item.sxname }}</span>
                    <el-tag size="mini">{{item.sqtypeone}}</el-tag>

                </h3>
                <div>
                    <el-row
                        v-for="(row,rowIndex) in appealInfo.infoList"
                        :key="rowIndex"
                    >
                        <el-col
                            v-for="(col,colIndex) in row"
                            :key="colIndex"
                            class="appeal-col"
                            :span="col.span"
                        >
                            <label class="col-label">{{col.label||col.labelFormatter(item)}}: </label>
                            <span
                                v-if="col.formatter"
                                class="col-content"
                            >{{col.formatter(item[col.key])}}</span>
                            <span
                                v-else
                                class="col-content"
                            >{{item[col.key]}}</span>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
        <el-image
            v-if="!appealInfo.data.length"
            class="img"
            :src="src"
        ></el-image>
        <AppealDetailDialog
            ref="dialog"
            @evaluate="afterEvaluate"
        ></AppealDetailDialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AppealDetailDialog from './AppealDetailDialog'
import { FormInput, FormSelect, FormDatePicker } from '@/components/form/index.js'
import { formatDate } from '@/utils/dateHandler.js'

const NOW = new Date()

export default {
    components: {
        AppealDetailDialog,
        FormInput, FormSelect, FormDatePicker,
    },
    data() {
        return {
            src: require('@/assets/404_images/no_data.png'),
            tabInfo: {
                active: '1',
                list: [
                    // { value: '0', label: '诉求完善' },
                    { value: '1', label: '处理中',count:'' },
                    { value: '2', label: '已解决',count: ''},
                    { value: '3', label: '待评价',count:'' },
                    { value: 'ypj', label: '已评价',count:''},
                ],
            },
            countObj:{},//我的申请分类总数
            // 搜索
            searchInfo: {
                data: {
                    sxname: '', // 事项名称
                    starttime: '', // 开始日期
                    endtime: '', // 结束日期
                    sqtypeone: '', // 诉求类别
                    sfjj: '', // 是否加急
                },
                themeList: [],
            },
            // 诉求
            appealInfo: {
                data: [],
                infoList: [
                    [
                        { label: '政府领导', key: 'leadername', span: 12 },
                        { labelFormatter: (item) => item.ggzw || '企业高管', key: 'ggmc', span: 12 },
                    ],
                    [
                        { label: '主管部门', key: 'deptname', span: 12 },
                        { label: '诉求时间', key: 'createtime', span: 12 },

                    ],
                    [
                        { label: '诉求内容', key: 'content', span: 24 },
                    ],
                ],
            },
        };
    },
    computed: {
        ...mapGetters({
            userInfo: 'getUserInfo',
            'getDictList': 'dictionary/getDictList',
            'getDictLabel': 'dictionary/getDictLabel',
        }),
    },
    created() {
        this.getThemeList()
        this.getList()
         this.getlabelCount()
    },
    methods: {
        search() {
            this.getList()
        },
        reset() {
            this.searchInfo.data.sxname = ''
            this.searchInfo.data.starttime = ''
            this.searchInfo.data.endtime = ''
            this.searchInfo.data.sqtypeone = ''
            this.searchInfo.data.sfjj = ''

            this.getList()
        },
        clickItem(item) {
            /**
             * 20210419
             * ID传给dialog，去做初始化工作
             */
            this.$refs['dialog'].showDialog(item.ID, item.pjid)
        },
        /**
         * @description: 评价诉求工单的回调
         * @param {String} appealId 诉求工单ID
         */
        afterEvaluate(appealId) {
            // 评价后，该诉求工单会从 待评价列表 中过滤掉
            // 手动删除操作
            let index = this.appealInfo.data.findIndex(cv => cv.ID === appealId)
            if (index != null) this.appealInfo.data.splice(index, 1)
        },
        /**
         * @description 查询政策列表
         * @param {Number} page 页码
         * @param {Number} size 每页记录数
         */
        getList(page = 1, size = 10) {
            /**
             * 20210420
             * 参考移动端，用现有的 我的诉求 列表数据接口
             */
            let url = '/dev-api/AppealMsgApi/sqlist'
            let params = JSON.parse(JSON.stringify(this.searchInfo.data))
            params.tyshxydm = this.userInfo.tyshxydm
            switch (this.tabInfo.active) {
                // 暂存
                case '0':
                    params.status = '9'
                    break
                // 处理中
                case '1':
                    params.ywstatus = '0,5'
                    Reflect.deleteProperty(params, 'status')
                    break
                // 已解决
                case '2':
                    params.ywstatus = '1'
                    Reflect.deleteProperty(params, 'status')
                    break
                // 待评价
                case '3':
                    params.ywstatus = '1'
                    params.pjstatus = '0'
                    Reflect.deleteProperty(params, 'status')
                    break
                // 已评价
                case 'ypj':
                    params.ywstatus = '1'
                    params.pjstatus = '1'
                    Reflect.deleteProperty(params, 'status')
                    break
                default:
                    break
            }
            this.appealInfo.data = []
            this.$httpApi.post(url, params).then(res => {
                console.log('getList:', res)
                this.appealInfo.data = res.data || []
            }).catch(err => {
                console.log('getList error:', err)
            })
        },
        /**
         * @description: 查询述求类别
         */
        getThemeList() {
            let url = '/dev-api/sqxdtjfx/data/find/sxqdtjfx_sql_countbyfwdx'
            this.$httpApi.post(url, {}).then(res => {
                // console.log('getThemeList:', res)
                this.searchInfo.themeList = res.data.map(cv => {
                    return {
                        value: cv.item_type_code,
                        label: cv.type_name,
                    }
                })
            }).catch(err => {
                console.log('getThemeList error:', err)
            })
        },
        getlabelCount(){
            let that=this
            let params={
                tyshxydm:this.userInfo.tyshxydm
            }
            let url='/dev-api/AppealMsgApi/countMySqNumber'
            this.$httpApi.post(url,params).then(r=>{
                that.tabInfo.list[0].count=r.data.clz
                that.tabInfo.list[1].count=r.data.yjj
                that.tabInfo.list[2].count=r.data.dpj
                that.tabInfo.list[3].count=r.data.ypj
            }).catch(e=>{
            })
        },
    },
    watch: {
        'tabInfo.active'(newVal) {
            this.getList()
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/com/theme.scss";
$tabColors: (
    "0": $col-blue,
    "1": $col-green,
    "2":#7e97ac,
    "3": $col-orange,
    // $col-red
);
.workplace-my-appeal {
    width: 100%;
    height: 100%;
    background-color: #ffffff;

    ::v-deep .el-tabs {
        .el-tabs__item {
            padding-right: 20px;
            padding-left: 20px;
            font-size: 16px;

            &:hover,
            &.is-active {
                color: $col-theme;
            }
            &.is-active {
                font-weight: bold;
            }
        }
    }

    ::v-deep .appeal-search-form {
        margin-bottom: 20px;

        .el-form-item {
            margin-bottom: 10px;
        }
        .el-form-item__content {
            > .el-input,
            > .el-date-editor {
                width: 200px;
            }
        }
    }
    .appeal-item {
        // display: flex;
        padding: 15px 10px;
        width: 100%;
        color: black;
        background-color: #f9f9f9;

        h3 {
            margin-bottom: 10px;

            ::v-deep .el-tag {
                margin-left: 10px;
            }
        }

        .appeal-col {
            display: flex;
            margin-bottom: 5px;
            font-size: 12px;

            .col-label {
                margin-right: 10px;
                color: #333333;
            }
            .col-content {
                color: #666666;
            }
        }
    }

    .appeal-item:hover {
        background-color: rgba(#eeeeee, 0.5);
    }
}
::v-deep .el-tabs__header {
    padding-top: 5px;

    .el-tabs__nav-wrap {
        .el-tabs__item {
            padding-right: 20px;
            padding-left: 20px;
            font-size: 16px;
            font-weight: bold;

            .tabItem-label {
                position: relative;
                display: -webkit-flex;
                display: flex;
                align-items: center;

                .tabItem-count {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    display: inline-block;
                    min-width: 16px;
                    height: 16px;
                    padding-right: 2px;
                    padding-left: 2px;
                    border-radius: 16px;
                    margin-left: 5px;
                    line-height: 16px;
                    text-align: center;
                    font-size: 12px;
                    color: #ffffff;
                    background-color: $col-red;
                    -webkit-transform: translate(calc(100% + 2px), -50%);
                    -moz-transform: translate(calc(100% + 2px), -50%);
                    -o-transform: translate(calc(100% + 2px), -50%);
                    -ms-transform: translate(calc(100% + 2px), -50%);
                    transform: translate(calc(100% + 2px), -50%);
                }
                @each $k, $v in $tabColors {
                    &.tabItem-label-#{$k} .tabItem-count {
                        background-color: $v;
                    }
                }
            }
            &:hover,
            &.is-active {
                color: $col-theme;

                @each $k, $v in $tabColors {
                    .tabItem-label-#{$k} {
                        color: $v;
                    }
                }
            }
        }
        .el-tabs__active-bar {
            background-color: $col-theme;
        }

        &::after {
            display: none;
        }
    }
}
</style>
