<!--
 * @Author: LXG
 * @Date: 2021-04-20
 * @LastEditor: LXG
 * @LastEditTime: 2021-05-17
 * @Description: 诉求工单详情弹窗
-->
<template>
    <el-dialog
        class="appeal-detail-dialog"
        :visible.sync="show"
        width="1024px"
        title="诉求详情"
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        @closed="closedDialog"
    >
        <div>
            <el-tabs
                v-model="tabInfo.active"
                type="card"
            >
                <el-tab-pane
                    label="基本信息"
                    name="base"
                >
                    <el-form
                        class="modules-base"
                        label-width="130px"
                    >
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="诉求编号">
                                    <span>{{baseData.id}}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="诉求方">
                                    <span>{{companyData.qymc}}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="诉求时间">
                                    <span>{{baseData.createtime}}</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-form-item label="诉求内容">
                                    <p>{{baseData.content}}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item :label="companyData.ggzw">
                                    <span>{{companyData.ggmc}}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="直达领导">
                                    <span>{{baseData.leadername}}</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="企业联络员">
                                    <span>{{companyData.nnrmc}}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="联络员电话">
                                    <span>{{companyData.nnrphone}}</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="所属行业">
                                    <span>{{companyData.hymc}}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="所属街道">
                                    <span>{{companyData.jdmc}}</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-form-item label="统一社会信用代码">
                                    <span>{{companyData.shxydm}}</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="主题">
                                    <span>{{baseData.sqtypeone}}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="分类">
                                    <span>{{baseData.sqtypetwo}}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="诉求事项">
                                    <span>{{baseData.sxname}}</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="主管部门">
                                    <span>{{baseData.deptname}}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="联办单位">
                                    <span>{{baseData.lbdeptname}}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="处理时限">
                                    <span>{{baseData.clsx}}</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-form-item label="领导意见">
                                    <p>{{baseData.pscontent||'无'}}</p>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane
                    label="具体要求"
                    name="more"
                >
                    <div>
                        <ParseFormViewDiv
                            v-if="baseData!=='{}'&&moreForm"
                            ref="parseFormViewDiv"
                            :formData="moreForm"
                            size="100%"
                            :readonly="cpu_readonly"
                        ></ParseFormViewDiv>
                    </div>
                </el-tab-pane>
                <el-tab-pane
                    label="材料信息"
                    name="material"
                >
                    <div
                        v-for="(item,index) in materialData"
                        :key="index"
                    >
                        <h3>{{item.clmc}}</h3>
                        <FormFileUploader
                            v-model="item.materialFiles"
                            :disabled="baseData.status!=='9'"
                            upload
                            :afterSelect="(filer,cb)=>afterSelect(item,filer,cb)"
                            @delete="(val)=>deleteFile(item,index,val)"
                        ></FormFileUploader>
                    </div>
                </el-tab-pane>
                <el-tab-pane
                    v-if="cpu_isYpj"
                    label="评价信息"
                    name="evaluation"
                >
                    <el-timeline>
                        <el-timeline-item
                            v-for="(item,index) in evaluationData"
                            :key="index"
                            type="success"
                            :timestamp="item.createtime"
                            placement="top"
                        >
                            <el-card>{{item.msg}}</el-card>
                        </el-timeline-item>
                    </el-timeline>
                </el-tab-pane>
                <el-tab-pane
                    v-if="!cpu_isZancun"
                    label="日志同步"
                    name="log"
                >
                    <el-timeline>
                        <el-timeline-item
                            v-for="(item,index) in logData"
                            :key="index"
                            type="success"
                            :timestamp="item.handletime"
                            placement="top"
                        >
                            <el-card>{{item.nodename}}</el-card>
                        </el-timeline-item>
                    </el-timeline>
                </el-tab-pane>
            </el-tabs>

            <!-- 服务评价 -->
            <div
                v-if="cpu_isDpj"
                class="module-dpj"
            >
                <h3 class="module-title">服务评价</h3>
                <el-form
                    :model="pjData"
                    label-width="100px"
                >
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="满意度">
                                <el-rate v-model="pjData.myd"></el-rate>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="处理速度">
                                <el-rate v-model="pjData.clsd"></el-rate>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="服务态度">
                                <el-rate v-model="pjData.fwtd"></el-rate>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="评价意见">
                                <FormTextarea v-model="pjData.msg"></FormTextarea>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </div>
        <template v-slot:footer>
            <div>
                <el-button
                    v-for="(item,index) in cpu_footBtns"
                    :key="index"
                    :type="item.type"
                    @click="clickFootBtn(item)"
                >{{item.label}}</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
import ParseFormViewDiv from '@/components/declareComponents/formMaking/ParseFormViewDiv'
import { FormTextarea, FormFileUploader } from '@/components/form/index.js'

export default {
    name: 'AppealDetailDialog',
    components: {
        ParseFormViewDiv,
        FormTextarea, FormFileUploader,
    },
    props: {},
    data() {
        return {
            show: false,
            tabInfo: {
                active: 'base',
            },
            appealId: '', // 诉求工单ID
            baseData: {}, // 基本信息
            companyData: {}, // 企业信息
            // 更多信息(具体要求)
            moreInfo: {
                // bsnum: '', // 业务流水号
                // formdata: '', // 表单结构JSON
                // formid: '', // 表单ID
                // formtype: '', // 表单类型
                // id: '',
                // subdata: '', // 表单数据
            },
            moreForm: '', // 更多信息(具体要求) 表单结构JSON
            moreData: '', // 更多信息(具体要求) 表单数据
            materialData: [], // 事项材料 数据
            evaluationData: [], // 评价信息
            logData: [], // 日志信息
            // 评价
            pjData: {
                myd: 5, // 满意度
                clsd: 5, // 处理速度
                fwtd: 5, // 服务态度
                msg: '', // 评价意见
            },
        }
    },
    computed: {
        /**
         * @description: 是否为暂存的状态
         */
        cpu_isZancun() {
            return this.baseData.status == '9'
        },
        /**
         * @description: 是否为待评价的状态
         */
        cpu_isDpj() {
            return (this.baseData.ywstatus == '1' && this.baseData.pjstatus == '0')
        },
        /**
         * @description: 是否为已评价的状态
         */
        cpu_isYpj() {
            return this.baseData.pjstatus == '1'
        },
        cpu_readonly() {
            if (this.baseData.status == '9') return false

            return true
        },
        cpu_footBtns() {
            let list = [
                { label: '关闭', handleName: 'cancel' },
            ]
            // 暂存
            if (this.baseData.status == '9') {
                list.push(
                    { label: '保存', type: 'warning', handleName: 'save' },
                    { label: '保存并确认', type: 'primary', handleName: 'saveConfirm' }
                )
            }
            // 评价
            if (this.cpu_isDpj) {
                list.push({ label: '评价', type: 'primary', handleName: 'evaluate' })
            }

            return list
        },
    },
    methods: {
        /**
         * @description: 展开dialog
         * @param {String} id 诉求ID
         * @param {String} pjId 评价ID
         */
        showDialog(id, pjId) {
            this.show = true
            this.appealId = id

            // id = '2104201155310004'
            this.getBaseData(id)
            this.getMoreData(id)
            this.getLogData(id)
            pjId && this.getEvaluationData(id)
        },
        closedDialog() {
            this.tabInfo.active = 'base'
            this.appealId = ''
            this.baseData = {}
            this.companyData = {}
            this.moreInfo = {}
            this.moreForm = ''
            this.moreData = ''
            this.materialData = []

            this.pjData = {
                myd: 5,
                clsd: 5,
                fwtd: 5,
                msg: '',
            }
        },
        /**
         * @description 选择文件后的钩子
         */
        afterSelect(material, filer, cb) {
            // console.log('afterSelect:', material, filer, cb)
            this.$httpApi.uploadFile(filer.file).then(res => {
                // console.log('uploadFile:', res)
                filer.fileid = res.fileId
                filer.filename = res.fileName
                filer.filepath = res.url
                filer.id = ''
                filer.ly = material.ly
                filer.mid = material.id
                filer.status = 1

                filer.url = `${this.$httpApi.baseUrl}/dev-api/fileManageApi/common/fileView/${res.fileId}`
                cb(true)
            }).catch(err => {
                console.log('uploadFile error:', err)
                cb(false)
            })
        },
        /**
         * @description 删除材料文件
         * @param {Object} material 材料信息
         * @param {Number} index 材料下标
         * @param {Object} val 回调参数
         */
        deleteFile(material, index, val) {
            // console.log('deleteFile:', material, index, val)
        },
        /**
         * @description: 点击底部按钮
         * @param {Object} btn 按钮信息
         */
        clickFootBtn(btn) {
            this[btn.handleName]()
        },
        cancel() {
            this.show = false
        },
        /**
         * @description: 保存暂存表单信息
         */
        save() {
            this.confirm({ formstatus: '0' })
        },
        /**
         * @description: 保存并确认表单信息
         */
        saveConfirm() {
            this.confirm({ formstatus: '1' })
        },
        /**
         * @description 提交暂存
         * @param {Object} options 选项
         */
        async confirm(options) {
            let params = {}

            params.id = this.baseData.id

            params.statusInfo = {
                start: true,
                jljb: false,
                zz: false,
                hz: false,
                wssl: false,
                bjgz: false,
                bjsl: false,
                blsl: false,
                back: false,
                end: false,
                qtsl: false,
                zcsl: false,
                zancun: true,
            }

            params.formstatus = options.formstatus || '0'

            params.baseinfo = {
                areaid: "",
                areaname: "",
                bsnum: "",
                bstype: "",
                cnsj: "",
                cnsjdw: "",
                dataid: "",
                deptid: "",
                deptname: "",
                dwbh: "",
                endtime: "",
                enduserid: "",
                factnbje: 0,
                flowid: "",
                gid: "",
                jljb: "",
                nbfje: 0,
                nbfjenote: "",
                nbfjeremark: "",
                nbjepolicy: "",
                ssgzr: "",
                status: "",
                sxid: this.baseData.sxid,
                sxmc: this.baseData.sxname,
                type: "",
                arrivetime: ""
            }

            // 校验表单
            // let newFormData = await this.$refs['parseFormViewDiv'].getFormData()
            // 不校验表单
            let newFormData = await this.$refs['parseFormViewDiv'].getTempSaveData()
            // 属性是对象的，也要转成JSON...
            if (newFormData) {
                for (const [k, v] of Object.entries(newFormData)) {
                    if (typeof v === 'object') {
                        newFormData[k] = JSON.stringify(newFormData[k])
                    }
                }
            }
            params.formInfo = [{
                id: this.moreInfo.id,
                formdata: this.moreInfo.formdata,
                formid: this.moreInfo.formid,
                formtype: this.moreInfo.formtype,
                subdata: newFormData ? JSON.stringify(newFormData) : ''
            }]

            params.operator = {
                apptype: '1',
                enterprise: {
                    legalcardnum: "",
                    legalcardtype: "",
                    legalperson: "",
                    unitaddress: "",
                    unitcardcode: this.companyData.shxydm,
                    unitcardtype: "",
                    unitemail: "",
                    unitname: this.companyData.qymc,
                    unitpostcode: "",
                    unittel: ""
                },
                reporter: {
                    //登录用户的信息,可能登录好之后要传?目前是写死
                    //操作人用戶账号
                    operateaccount: "",
                    //操作人用戶ID
                    operateid: "",
                    // 操作人地址
                    optaddress: "深圳福田区梅林街道xx",
                    optbirthday: "1999-09-09",
                    optcertificatename: "二代身份证",
                    optcertificatenumber: "42112122211212",
                    optcertificatetype: "2",
                    optemail: "2525@qq.com",
                    optgender: "男",
                    optmobilephone: "14565456554",
                    optname: "李小龙"
                }
            }
            params.sqInfo = JSON.parse(JSON.stringify(this.baseData))
            params.sqInfo.nnrid = this.companyData.nnrid
            params.sqInfo.fqzyid = this.companyData.fqzyid
            params.sqInfo.tyshxydm = this.companyData.shxydm

            params.materials = JSON.parse(JSON.stringify(this.materialData))
            for (let i = 0; i < params.materials.length; i++) {
                const material = params.materials[i]
                material.bsnum = this.moreInfo.bsnum
                material.files = material.materialFiles.map(cv => {
                    return {
                        fileid: cv.fileid,
                        filename: cv.filename,
                        filepath: cv.filepath,
                        id: cv.id,
                        ly: cv.ly,
                        mid: cv.mid,
                        status: cv.status,
                    }
                })
            }

            this.submitTemp(params, () => {
                this.$message.success('操作成功')
                this.show = false
            })
        },
        /**
         * @description: 评价
         */
        evaluate() {
            let url = '/dev-api/sqAdvice/add'
            let params = {
                userid: this.baseData.userid,
                bsnum: this.appealId,
                itemtype: '3',
                ...this.pjData,
            }
            this.$httpApi.post(url, params).then(res => {
                // console.log('evaluate:', res)
                this.$message.success('操作成功')
                this.show = false

                this.$emit('evaluate', this.appealId)
            }).catch(err => {
                console.log('evaluate error:', err)
            })
        },
        /**
         * @description: 查询诉求工单基本信息
         * @param {String} id 诉求ID
         */
        getBaseData(id) {
            let url = `/dev-api/AppealMsgApi/sqdetail/${id}`
            let params = {}
            this.$httpApi.post(url, params).then(res => {
                console.log('getBaseData:', res)
                this.baseData = res.data

                this.getCompanyData(this.baseData.userid, this.baseData.leaderid)
                this.getMaterialData(this.appealId)

            }).catch(err => {
                console.log('getBaseData error:', err)
            })
        },
        /**
         * @description: 查询企业信息
         * @param {String} userid 用户ID
         * @param {String} touserid 领导ID
         */
        getCompanyData(userid, touserid) {
            if (!userid || !touserid) return

            let url = '/dev-api/sqzdManager/data/find/sq_qybase_list'
            let params = {
                userid,
                touserid,
            }
            this.$httpApi.post(url, params).then(res => {
                // console.log('getCompanyData:', res)
                this.companyData = res.data[0]
            }).catch(err => {
                console.log('getCompanyData error:', err)
            })
        },
        /**
         * @description: 查询诉求工单更多信息(具体要求)
         * @param {String} id 诉求ID
         */
        getMoreData(id) {
            let url = `/dev-api/userBusiness/queryFormData?bsnum=${id}`
            let params = {}
            this.$httpApi.get(url, params).then(res => {
                // console.log('getMoreData:', res)
                this.moreInfo = res.data
                this.moreForm = JSON.parse(res.data.formdata)
                this.moreData = JSON.parse(res.data.subdata)
                this.$nextTick(() => {
                    this.$refs['parseFormViewDiv'].fillFormData(this.moreForm, this.moreData)
                })
            }).catch(err => {
                console.log('getMoreData error:', err)
            })
        },
        /**
         * @description: 查询诉求工单材料
         * @param {String} id 诉求ID
         */
        getMaterialData(id) {
            /**
             * 20210422
             * 先获取 诉求工单的材料
             * 如果没有，说明第一次暂存的时候，没有把事项对应的材料列表绑定给诉求工单
             * 这时需要回头做这个操作，去获取 事项对应的材料列表 ，然后绑给诉求工单
             */
            let url = `/dev-api/business/handle/sq/detail/${id}`
            let params = {}
            this.$httpApi.get(url, params).then(res => {
                // console.log('getMaterialData:', res)
                if (res.data && !!res.data.length) {
                    this.materialData = res.data
                    // 转换一下组件用的字段，可以不转，但希望以组件为统一标准
                    for (let i = 0; i < this.materialData.length; i++) {
                        const material = this.materialData[i]
                        material.files = []
                        for (let j = 0; j < material.materialFiles.length; j++) {
                            const file = material.materialFiles[j]
                            file.name = file.filename
                            file.url = `${this.$httpApi.baseUrl}/dev-api/fileManageApi/common/fileView/${file.fileid}`
                        }
                    }
                } else {
                    this.getMaterialList(this.baseData.sxid)
                }
            }).catch(err => {
                console.log('getMaterialData error:', err)
            })
        },
        /**
         * @description: 查询事项对应的材料列表
         * @param {String} id 事项ID
         */
        getMaterialList(id) {
            let url = `/dev-api/basicinfo/clxx/list?itemId=${id}`
            let params = {}
            this.$httpApi.get(url, params).then(res => {
                // console.log('getMaterialList:', res)
                this.materialData = (res.rows || []).map(cv => {
                    return {
                        bsnum: cv.bsnum || '', // 业务流水号
                        certid: cv.certid || '', // 证照ID
                        clbm: cv.clbm || '', // 材料编码
                        clid: cv.clid || '', // 材料ID
                        clmc: cv.clname || '', // 材料名称
                        copynum: cv.copynum || '', // 复印件份数
                        files: [
                            // {
                            //     fileid: '', // 文件ID
                            //     filename: '', // 文件名称
                            //     filepath: '', // 文件路径
                            //     id: '',
                            //     ly: '', // 来源
                            //     mid: '', // 材料ID
                            //     status: '', // 状态{'1':有效, '0':作废}
                            // }
                        ], // 附件
                        materialFiles: [], // 附件(临时)
                        id: '',
                        isneed: cv.isneed || '',
                        ly: cv.ly || '',
                        orinum: cv.orinum || '',
                        sfrq: cv.sfrq || '',
                        shyj: cv.shyj || '',
                        shzt: cv.shzt || '',
                        status: cv.status || '',
                        submittype: cv.submittype || '',
                        sxid: cv.sxid || '',
                        yburl: cv.yburl || '',
                    }
                })
            }).catch(err => {
                console.log('getMaterialList error:', err)
            })
        },
        /**
         * @description: 查询日志信息
         * @param {String} id 诉求ID
         */
        getLogData(id) {
            // 没有单独拿日志的接口，从 办件详情 中取
            let url = `/dev-api/userBusiness/getBusinessInfo?id=${id}`
            let params = {}
            this.$httpApi.post(url, params).then(res => {
                // console.log('getLogData:', res)
                if (res.code == 200) {
                    this.logData = res.data.log || []
                }
            }).catch(err => {
                console.log('getLogData error:', err)
            })
        },
        /**
         * @description: 查询评价信息
         * @param {String} id 诉求工单的评价ID
         */
        getEvaluationData(id) {
            let url = '/dev-api/sqAdvice/list'
            let params = {
                bsnum: id,
                itemtype: '3',
                pjstatus: '1',
            }
            this.$httpApi.post(url, params).then(res => {
                console.log('getEvaluationData:', res)
                if (res.code == 200) {
                    this.evaluationData = res.data || []
                }
            }).catch(err => {
                console.log('getEvaluationData error:', err)
            })
        },
        /**
         * @description: 提交暂存
         * @param {Object} params 请求参数
         * @return {*}
         */
        submitTemp(params, callback) {
            let url = '/dev-api/business/handle/zancun'

            this.$httpApi.post(url, params).then(res => {
                // console.log('submitTemp:', res)
                callback()
            }).catch(err => {
                console.log('submitTemp error:', err)
            })
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
    max-height: 60vh;
    overflow-y: auto;

    .el-tabs__content {
        padding: 0 10px 10px 10px;
    }
}
.module-title {
    position: relative;
    padding-left: 16px;
    margin-bottom: 16px;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 5px;
        height: 100%;
        background-color: #3699ff;
        border-radius: 5px;
    }
}
::v-deep .modules-base {
    .el-form-item__label,
    .el-form-item__content {
        line-height: 20px;
    }
    .el-form-item__label {
        font-weight: bold;
    }
    .el-form-item__content p {
        padding: 5px;
        border: 1px solid #eeeeee;
    }
}
.module-dpj {
    padding-top: 10px;
    border-top: 1px solid #eeeeee;
}

.module-dpj {
    ::v-deep .el-rate {
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}
</style>